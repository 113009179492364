import { updateDoc, doc } from "@firebase/firestore";
import { db } from "../FirebaseConfig.ts";
import { useState } from "react";

const useUpdateDoc = () => {
  const [fetching, setFetching] = useState(false);

  const handleUpdate = async (url, id, data) => {
    setFetching(true);
    try {
      const docRef = doc(db, url, id);
      await updateDoc(docRef, data);
    } catch (error) {
      console.error("Error updating document:", error);
    } finally {
      setFetching(false);
    }
  };

  return { handleUpdate, fetching };
};

export default useUpdateDoc;
