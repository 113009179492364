import { useState } from "react";
import { PDFDocument, rgb } from "pdf-lib";
import { planPagos } from "../../../utils/planPagos";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
dayjs.locale("es");

function DownloadPDF({ name, course, disabled, document, data }) {
  const [loading, setLoading] = useState(false);

  const { schedule, totalCredit, totalInterest } = planPagos(data);

  const generarCertificado = async () => {
    setLoading(true);

    // Crear un nuevo documento PDF
    const pdfDoc = await PDFDocument.create();

    // Agregar una página nueva
    const newPage = pdfDoc.addPage([595.28, 841.89]); // A4 size
    const pageWidth = newPage.getWidth();
    const pageHeight = newPage.getHeight();

    // Configurar las posiciones y dimensiones de la tabla
    const tableStartX = 50;
    const tableStartY = pageHeight - 100; // Ajusta según el espacio que necesites
    const rowHeight = 20;
    const columnWidths = [50, 90, 80, 90, 70, 70, 70]; // Ancho de cada columna
    const headers = [
      "Cuotas",
      "Fecha de pago",
      "Valor préstamo",
      "Cuota a pagar",
      "Capital",
      "Interés",
      "Saldo deuda",
    ];

    // Dibujar la cabecera de la tabla
    headers.forEach((header, i) => {
      newPage.drawText(header, {
        x: tableStartX + columnWidths.slice(0, i).reduce((a, b) => a + b, 0),
        y: tableStartY,
        size: 10,
        color: rgb(0, 0, 0),
      });
    });

    // Dibujar las filas de la tabla con datos del plan de pagos
    schedule.forEach((row, rowIndex) => {
      headers.forEach((_, colIndex) => {
        const text = [
          row.cuota.toString(),
          row.fechaPago.toString(),
          row.valorPrestamo.toLocaleString(),
          row.cuotaAPagar.toLocaleString(),
          row.capital.toLocaleString(),
          row.interes.toLocaleString(),
          row.saldoDeuda.toLocaleString(),
        ][colIndex];

        newPage.drawText(text, {
          x:
            tableStartX +
            columnWidths.slice(0, colIndex).reduce((a, b) => a + b, 0),
          y: tableStartY - (rowIndex + 1) * rowHeight,
          size: 10,
          color: rgb(0, 0, 0),
        });
      });
    });

    // Agregar un título en la parte superior de la página
    newPage.drawText("Plan de pagos", {
      x: 45,
      y: pageHeight - 50,
      size: 18,
      color: rgb(0, 0, 0),
    });

    // Guardar el PDF modificado como bytes
    const pdfBytes = await pdfDoc.save();

    // Crear un Blob con los bytes del PDF generado
    const blob = new Blob([pdfBytes], { type: "application/pdf" });

    // Crear una URL para el Blob
    const url = URL.createObjectURL(blob);

    // Abrir una nueva pestaña con el PDF generado
    const newWindow = window.open(url, "_blank");

    setTimeout(() => {
      newWindow.document.title = "Plan de Pagos - " + name;
    }, 100); // Ajusta el tiempo según sea necesario

    setLoading(false);
  };

  return (
    <div className="w-fit -mt-4 mb-3">
      <button
        onClick={() => generarCertificado()}
        className="bg-primary rounded-lg py-1 px-2 text-black font-semibold mt-3"
      >
        Descargar PDF
      </button>
    </div>
  );
}

export default DownloadPDF;
