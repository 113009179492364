"use client";

import React, { useState } from "react";
import FormKnowMoney from "./formKnowMoney";
import FormUnknowMoney from "./formUnknowMoney";
import FormStepTwo from "./formStepTwo";

function Form({
  sended,
  setSended,
  data,
  setData,
  files,
  setFiles,
  step,
  setStep,
  admin,
}) {
  const [activeMoney, setActiveMoney] = useState(0);

  return (
    <div className="bg-richBlack rounded-xl p-3 h-fit">
      {step === 0 ? (
        <>
          <p className="text-white text-center text-3xl font-bold">
            ¿Sabes cuánto dinero necesitas?
          </p>

          <div className="grid grid-cols-2 gap-3 mt-4">
            <div className="flex items-center ps-4 border border-gray-200 rounded-xl ">
              <input
                id="bordered-radio-1"
                type="radio"
                value="0"
                name="bordered-radio"
                checked={activeMoney === 0}
                onChange={(e) => {
                  setActiveMoney(0);
                  setData({ ...data, knowMoney: true });
                }}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 cursor-pointer"
              />
              <label
                htmlFor="bordered-radio-1"
                className="w-full py-4 ms-2 text-sm font-medium text-white cursor-pointer"
              >
                Sí{" "}
                <span className="text-xs">
                  (se calculará un monto y un plazo dependiendo del plazo que
                  puedas pagar)
                </span>
              </label>
            </div>
            <div className="flex items-center ps-4 border border-gray-200 rounded-xl">
              <input
                id="bordered-radio-2"
                type="radio"
                value="1"
                name="bordered-radio"
                checked={activeMoney === 1}
                onChange={() => {
                  setActiveMoney(1);
                  setData({ ...data, knowMoney: false });
                }}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 cursor-pointer"
              />
              <label
                htmlFor="bordered-radio-2"
                className="w-full py-4 ms-2 text-sm font-medium text-white cursor-pointer"
              >
                No{" "}
                <span className="text-xs">
                  (se calculará un monto y un plazo dependiendo del plazo que
                  puedas pagar)
                </span>
              </label>
            </div>
          </div>
          {activeMoney === 0 ? (
            <FormKnowMoney setStep={setStep} setData={setData} data={data} />
          ) : (
            <FormUnknowMoney setStep={setStep} setData={setData} data={data} />
          )}
        </>
      ) : (
        <FormStepTwo
          setActiveMoney={setActiveMoney}
          activeMoney={activeMoney}
          setStep={setStep}
          setData={setData}
          data={data}
          files={files}
          setFiles={setFiles}
          sended={sended}
          setSended={setSended}
          admin={admin}
        />
      )}
    </div>
  );
}

export default Form;
