import React, { useState } from "react";
import { useSnapshot } from "../../../../hooks/useSnapshot";
import { useSnapshotWhere } from "../../../../hooks/useSnapshotWhere";
import Spinner from "../../../../components/Spinner";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../../FirebaseConfig.ts";
import useUpdateDoc from "../../../../hooks/useUpdateDoc.js";
import { obtenerFechaActual } from "../../../../utils/actualDate.js";
import WithoutCodeudor from "../../../../components/WithoutCodeudor/index.js";

function ModalContainer({ currentRequest }) {
  const [dataC, setDataC] = useState({
    name: "",
    email: "",
  });
  const [fetching, setFetching] = useState(false);
  const [done, setDone] = useState(false);
  const [doneStatus, setDoneStatus] = useState(false);
  const [doneCompany, setDoneCompany] = useState(false);
  const [status, setStatus] = useState(currentRequest.status);

  const { data, isFetching } = useSnapshotWhere("codeudor", {
    filter: {
      firstCondition: "requestId",
      secondCondition: "==",
      thirdCondition: currentRequest.id,
    },
  });

  const { data: companies, isFetching: fetchingCompanies } =
    useSnapshot("companies");

  const { handleUpdate } = useUpdateDoc();

  const handleInsertCodeudor = async () => {
    try {
      setFetching(true);
      const res = await addDoc(collection(db, "codeudor"), {
        ...dataC,
        requestId: currentRequest.id,
        cert_laboral: "",
        comp_nomina: "",
        fot_cedula: "",
        dec_renta: "",
      });

      handleUpdate("requests", currentRequest.id, { codeudor: res.id });
      handleUpdate("codeudor", res.id, { uuid: res.id });

      setFetching(false);
      setDataC({
        name: "",
        email: "",
      });
      setDone(true);
    } catch (error) {
      console.log(error);
      setFetching(false);
      setDone(false);
    } finally {
      setFetching(false);
    }
  };

  const handleUpdateRequest = async () => {
    let data = { status: status };

    if (status === "aprobado") data.start_date = obtenerFechaActual();
    try {
      setFetching(true);

      handleUpdate("requests", currentRequest.id, data);

      setFetching(false);

      setDoneStatus(true);
    } catch (error) {
      console.log(error);
      setFetching(false);
      setDoneStatus(false);
    } finally {
      setFetching(false);
    }
  };

  const [company, setCompany] = useState(currentRequest.companyId);
  const handleUpdateCompany = async () => {
    try {
      setFetching(true);

      handleUpdate("requests", currentRequest.id, {
        companyId: company,
      });

      setFetching(false);

      setDoneCompany(true);
    } catch (error) {
      console.log(error);
      setFetching(false);
      setDoneCompany(false);
    } finally {
      setFetching(false);
    }
  };

  const codeudor = data[0];

  return (
    <div>
      <p className="text-xl font-semibold">
        Editar solicitud de: {currentRequest.name} {currentRequest.lastName}
      </p>
      <hr className="mt-2" />

      <p className="text-left mt-3">Archivos subidos por el empleado:</p>
      <div className="grid grid-cols-4 gap-2">
        <Item href={currentRequest.cert_laboral} name="Certificación Laboral" />
        <Item href={currentRequest.comp_nomina} name="Comprobante Nómina" />
        <Item href={currentRequest.dec_renta} name="Declaración de Renta" />
        <Item href={currentRequest.fot_cedula} name="Fotocopia Cédula" />
      </div>

      <hr className="mt-5" />

      <div className="grid grid-cols-2 gap-5 mt-5">
        <div>
          <label
            for="countries"
            className="block mb-2 text-sm font-medium text-gray-900 text-left"
          >
            Estado:
          </label>
          <div className="flex gap-2 items-center">
            <select
              onChange={(e) => setStatus(e.target.value)}
              value={status}
              id="countries"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            >
              <option value="pendiente" disabled>
                Pendiente
              </option>
              <option value="aprobado">Aprobado</option>
              <option value="rechazado">Rechazado</option>
            </select>

            <button
              disabled={fetching}
              aria-disabled={fetching}
              onClick={() => handleUpdateRequest()}
              className="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
            >
              Actualizar
            </button>
          </div>
          {doneStatus && (
            <p className="text-green-700 text-sm mt-2">
              Se actualizó el estado correctamente
            </p>
          )}
        </div>

        <div>
          <label
            for="countries"
            className="block mb-2 text-sm font-medium text-gray-900 text-left"
          >
            Empresa:
          </label>
          <div className="flex gap-2 items-center">
            <select
              onChange={(e) => setCompany(e.target.value)}
              value={company}
              id="countries"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            >
              <option value=""></option>
              {companies.map((el) => (
                <option key={el.id} value={el.id}>
                  {el.name_company}
                </option>
              ))}
            </select>

            <button
              disabled={fetching}
              aria-disabled={fetching}
              onClick={() => handleUpdateCompany()}
              className="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
            >
              Actualizar
            </button>
          </div>
          {doneCompany && (
            <p className="text-green-700 text-sm mt-2">
              Empresa relacionada correctamente
            </p>
          )}
        </div>
      </div>

      <hr className="mt-5" />

      <div className="text-left">
        {done && (
          <p className="text-green-700 text-sm mt-2">
            Se agregó correctamente el codeudor, se le envió un correo con toda
            la información
          </p>
        )}
        <p className="mb-0 mt-3">Codeudor: {data[0]?.name}</p>
        {isFetching ? (
          <Spinner />
        ) : data ? (
          data.length > 0 ? (
            <div className="grid grid-cols-4 gap-2">
              <Item
                href={codeudor.cert_laboral ? codeudor.cert_laboral : null}
                name="Certificación Laboral"
                noActive={codeudor.cert_laboral ? false : true}
              />
              <Item
                href={codeudor.comp_nomina ? codeudor.comp_nomina : null}
                name="Comprobante Nómina"
                noActive={codeudor.comp_nomina ? false : true}
              />
              <Item
                href={codeudor.dec_renta ? codeudor.dec_renta : null}
                name="Declaración de Renta"
                noActive={codeudor.dec_renta ? false : true}
              />
              <Item
                href={codeudor.fot_cedula ? codeudor.fot_cedula : null}
                name="Fotocopia Cédula"
                noActive={codeudor.fot_cedula ? false : true}
              />
            </div>
          ) : (
            <div className="bg-gray-100 rounded-md w-fit p-2 mt-3">
              <p>No hay codeudor</p>
              <div className="mt-3">
                <div className="flex items-center">
                  <div className="flex gap-3">
                    <input
                      type="text"
                      id="simple-search"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="Correo"
                      required
                      value={dataC.email}
                      onChange={(e) =>
                        setDataC({ ...dataC, email: e.target.value })
                      }
                    />

                    <input
                      type="text"
                      id="simple-search"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="Nombres"
                      required
                      value={dataC.name}
                      onChange={(e) =>
                        setDataC({ ...dataC, name: e.target.value })
                      }
                    />
                  </div>
                  <button
                    disabled={fetching}
                    aria-disabled={fetching}
                    onClick={() => handleInsertCodeudor()}
                    className="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
                  >
                    Agregar Codeudor
                  </button>
                </div>
              </div>
            </div>
          )
        ) : (
          ""
        )}
      </div>

      <hr className="mt-5" />

      <p className="mb-0 mt-3 text-left">Archivos para enviarle al empleado</p>

      <div className="w-fit">
        <WithoutCodeudor data={currentRequest} />
      </div>
    </div>
  );
}

export function Item({ href, name, noActive }) {
  return (
    <a href={href} target="_blank" rel="noreferrer">
      <div
        className={`${
          noActive
            ? "bg-red-300 border-red-300"
            : "border-gray-500 hover:bg-secondary hover:border-secondary hover:text-white cursor-pointer"
        } border rounded-lg mt-5 text-center text-sm px-2 py-3`}
      >
        {noActive ? `Falta: ${name}` : name}
      </div>
    </a>
  );
}

export default ModalContainer;
