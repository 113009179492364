import React from "react";
import Banner from "../../components/Banner";

function Companies() {
  return (
    <div>
      <Banner company />
    </div>
  );
}

export default Companies;
