import "./styles.css";

const Loading = () => {
  return (
    <>
      <div className="loading--section">
        <img
          src="/favicon_lendy.png"
          width="60"
          height="60"
          alt="logo-lendy"
          style={{ margin: "auto" }}
        />
        <div className="loader" />
      </div>
    </>
  );
};

export default Loading;
