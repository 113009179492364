import React from "react";

function Input({ label, value, type, placeholder, ...rest }) {
  return (
    <div className="mb-1">
      <label
        htmlFor="text"
        className="block mb-2 text-md font-medium text-black "
      >
        {label}
      </label>
      <input
        type={type ? type : "text"}
        id="text"
        className="bg-gray-300 bg-opacity-20 text-black text-sm rounded-lg block w-full p-2.5 focus-0"
        placeholder={placeholder ? placeholder : ""}
        value={value}
        required
        {...rest}
      />
    </div>
  );
}

export default Input;
