import React from "react";
import HomeLogin from "./components/home";

function Login() {
  return (
    <div className="rounded-sm shadow-default">
      <div className="flex flex-wrap items-center justify-center h-[100vh]">
        <HomeLogin className="xl:w-1/2" />
      </div>
    </div>
  );
}

export default Login;
