export const formatNumber = (amount, onlyPoints) => {
  // Convierte el número a string y separa por punto decimal
  if (amount) {
    const parts = amount.toString().split(".");

    // Formatea la parte de los millones con comas
    const formattedMillions = parseInt(parts[0]).toLocaleString("es-CO", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    // Reemplaza el punto por comilla simple en la parte de los miles
    const formattedThousands =
      parts.length > 1 ? parts[1].replace(".", "'") : "";

    // Concatena las partes formateadas
    if (onlyPoints) {
      return `${formattedMillions}${formattedThousands}`;
    } else {
      return `$${formattedMillions}${formattedThousands} COP`;
    }
  } else {
    return 0;
  }
};

export const formatNumberUSD = (amount, onlyPoints) => {
  // Convierte el número a string y separa por punto decimal
  const parts = amount.toString().split(".");

  // Formatea la parte de los millones con comas
  const formattedMillions = parseInt(parts[0]).toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  // Reemplaza el punto por comilla simple en la parte de los miles
  const formattedThousands = parts.length > 1 ? parts[1].replace(".", "'") : "";

  // Concatena las partes formateadas
  if (onlyPoints) {
    return `${formattedMillions}${formattedThousands}`;
  } else {
    return `$${formattedMillions}${formattedThousands} USD`;
  }
};
