"use client";

import React, { useState } from "react";
import FormStepTwo from "./formStepTwo";

function FormCodeudor({ dataCodeudor }) {
  const [step, setStep] = useState(0);

  const [activeMoney, setActiveMoney] = useState(0);
  const [data, setData] = useState({
    identity: "",
    expedition: "",
  });

  const [files, setFiles] = useState({
    cert_laboral: "",
    comp_nomina: "",
    fot_cedula: "",
    dec_renta: "",
  });

  return (
    <div className="bg-richBlack rounded-xl p-3 h-fit">
      <p className="text-white text-center text-3xl font-bold">
        Completa tus datos
      </p>

      <FormStepTwo
        setActiveMoney={setActiveMoney}
        activeMoney={activeMoney}
        setStep={setStep}
        setData={setData}
        data={data}
        files={files}
        setFiles={setFiles}
        dataCodeudor={dataCodeudor}
      />
    </div>
  );
}

export default FormCodeudor;
