import React from "react";

function Logo() {
  return (
    <div className="flex h-[34px]">
      <img
        src="/logo_lendy.png"
        width={200}
        height={200}
        alt="logo lendy"
        quality={100}
        className="object-contain"
      />
    </div>
  );
}

export default Logo;
