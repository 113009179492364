import Spinner from "../../../components/Spinner";
import { formatNumber } from "../../../utils/format";
import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import ModalContainer from "../components/Table.js/ModalContainer.js";
import { Link } from "react-router-dom";
import { useSnapshotWhere } from "../../../hooks/useSnapshotWhere";
import { db } from "../../../FirebaseConfig.ts";
import { collection, getDocs, query, where } from "firebase/firestore";

function Table() {
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [activeSearch, setActiveSearch] = useState(false);
  const [number, setNumber] = useState("");

  const categoriesCollection = collection(db, "requests");

  const fetchCategories = async () => {
    try {
      setIsFetching(true);
      setActiveSearch(true);
      const q = query(categoriesCollection, where("identity", "==", number));
      const data = await getDocs(q);

      setData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    } catch (error) {
      console.log(error);

      setIsFetching(false);
    } finally {
      setIsFetching(false);
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [currentRequest, setCurrentRequest] = useState({});

  return (
    <>
      <div className="max-w-3xl mx-auto mb-6">
        <label
          for="default-search"
          className="mb-2 text-sm font-medium text-gray-900 sr-only "
        >
          Buscar
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 "
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWsidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="search"
            id="default-search"
            className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
            placeholder="Buscar por número de cédula"
            required
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />
          <button
            onClick={() => fetchCategories()}
            className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
          >
            Buscar
          </button>
        </div>
      </div>

      <Modal showModal={showModal} setShowModal={setShowModal}>
        <ModalContainer currentRequest={currentRequest} />
      </Modal>
      <div
        className={`relative shadow-md sm:rounded-lg ${
          isFetching ? "" : "overflow-x-auto"
        }`}
      >
        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3">
                #
              </th>
              <th scope="col" className="px-6 py-3">
                Nombres
              </th>
              <th scope="col" className="px-6 py-3">
                Compañia
              </th>
              <th scope="col" className="px-6 py-3">
                Celular
              </th>
              <th scope="col" className="px-6 py-3">
                Cédula
              </th>
              <th scope="col" className="px-6 py-3">
                Monto
              </th>
              <th scope="col" className="px-6 py-3">
                Codeudor
              </th>
              <th scope="col" className="px-6 py-3">
                Estado
              </th>
              <th scope="col" className="px-6 py-3">
                Acción
              </th>
            </tr>
          </thead>

          <tbody className="bg-white">
            {isFetching ? (
              <tr>
                <td colSpan="100%" className="text-center">
                  <Spinner />
                </td>
              </tr>
            ) : !activeSearch ? (
              <tr>
                <td colSpan="100%" className="text-center text-lg py-3">
                  Busca una solicitud por número de cédula
                </td>
              </tr>
            ) : data.length === 0 ? (
              <tr>
                <td colSpan="100%" className="text-center text-lg py-3">
                  No existen solicit
                </td>
              </tr>
            ) : (
              data?.map((el, index) => {
                return (
                  <tr
                    className="odd:bg-white even:bg-gray-50  border-b"
                    key={el.id}
                  >
                    <td className="px-6 py-4">{index + 1}</td>
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      {`${el.name} ${el.lastName}`}
                    </th>
                    <td className="px-6 py-4">
                      {el.companyId ? (
                        <Link
                          to={`/plataforma/compania/${el.companyId}/${el.id}`}
                        >
                          <button className="bg-blue-700 rounded-lg px-2 py-1 text-white">
                            Ver
                          </button>
                        </Link>
                      ) : (
                        ""
                      )}
                    </td>
                    <td className="px-6 py-4">{el.cellphone}</td>
                    <td className="px-6 py-4">{el.identity}</td>
                    <td className="px-6 py-4">{formatNumber(el.ammount)}</td>
                    <td className="">
                      {el.codeudor === "" ? (
                        <div className="bg-gray-600 rounded-xl px-2 py-1 w-fit m-auto font-semibold text-white">
                          Pendiente
                        </div>
                      ) : el.codeudor ? (
                        <div className="bg-green-600 rounded-xl px-2 py-1 w-fit m-auto font-semibold text-white">
                          Con Codeudor
                        </div>
                      ) : (
                        <div className="bg-red-700 rounded-xl px-2 py-1 w-fit m-auto font-semibold text-white">
                          Sin Codeudor
                        </div>
                      )}
                    </td>
                    <td className="px-6 py-4">
                      {el.status === "pendiente" ? (
                        <div className="bg-robin rounded-xl px-2 py-1 w-fit m-auto font-semibold text-white">
                          Pendiente
                        </div>
                      ) : el.status === "rechazado" ? (
                        <div className="bg-red-500 rounded-xl px-2 py-1 w-fit m-auto font-semibold text-white">
                          Rechazado
                        </div>
                      ) : (
                        <div className="bg-blue-500 rounded-xl px-2 py-1 w-fit m-auto font-semibold text-white">
                          Aprobado
                        </div>
                      )}
                    </td>

                    <td className="px-6 py-4">
                      <button
                        onClick={() => {
                          setShowModal(true);
                          setCurrentRequest(el);
                        }}
                        className="font-medium text-blue-600 hover:underline"
                      >
                        Editar
                      </button>
                      <Link
                        to={`/plataforma/simulador/${el.identity}/${el.id}`}
                      >
                        <button
                          onClick={() => {
                            setShowModal(true);
                            setCurrentRequest(el);
                          }}
                          className="font-medium text-gray-600 hover:underline ml-2"
                        >
                          Plan de pagos
                        </button>
                      </Link>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Table;
