"use client";

import { useAuthState } from "react-firebase-hooks/auth";
import Sidebar from "./components/Sidebar";
import { auth } from "../../FirebaseConfig.ts";
import Loading from "../../components/Loading";
import { useNavigate } from "react-router-dom";

export default function LayoutPlatform({ children }) {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  if (loading) {
    return <Loading />;
  } else if (!user) {
    return navigate("/login");
  }

  return <Sidebar>{children}</Sidebar>;
}
