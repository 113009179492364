import { useState, useEffect } from "react";
import { collection, onSnapshot } from "@firebase/firestore";
import { db } from "../FirebaseConfig.ts";

export const useSnapshot = (collect) => {
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  useEffect(() => {
    setIsFetching(true);
    const todoRef = collection(db, collect);

    const subscriber = onSnapshot(todoRef, {
      next: (snapshot) => {
        const data = [];
        snapshot.docs.forEach((doc) => {
          data.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setData(data);
        setIsFetching(false);
      },
    });

    return () => subscriber();
  }, []);

  return { data, setData, isFetching };
};
