import { BrowserRouter, Route, Routes } from "react-router-dom";
import Banner from "./components/Banner";
import Login from "./pages/login/page";
import Platform from "./pages/plataforma/page";
import LayoutPlatform from "./pages/plataforma/layout";
import Simulador from "./pages/plataforma/simulador";
import Companies from "./pages/companies";
import Tasa from "./pages/plataforma/tasa";
import Codeudor from "./pages/codeudor";
import Creditos from "./pages/plataforma/creditos";
import PlanDePagos from "./pages/plataforma/plan-de-pagos";
import Compania from "./pages/plataforma/compania";

function RouterWrapper() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Banner />} />
          <Route path="/empresas" element={<Companies />} />
          <Route path="/codeudor/:id" element={<Codeudor />} />

          <Route path={`/login`} element={<Login />} />
          <Route
            path={`/plataforma`}
            element={
              <LayoutPlatform>
                <Platform />
              </LayoutPlatform>
            }
          />
          <Route
            path={`/plataforma/creditos`}
            element={
              <LayoutPlatform>
                <Creditos />
              </LayoutPlatform>
            }
          />
          <Route
            path={`/plataforma/simulador/:identity/:id`}
            element={
              <LayoutPlatform>
                <PlanDePagos />
              </LayoutPlatform>
            }
          />
          <Route
            path={`/plataforma/compania/:idCompany/:idRequest`}
            element={
              <LayoutPlatform>
                <Compania />
              </LayoutPlatform>
            }
          />
          <Route
            path={`/plataforma/simulador`}
            element={
              <LayoutPlatform>
                <Simulador />
              </LayoutPlatform>
            }
          />
          <Route
            path={`/plataforma/tasa`}
            element={
              <LayoutPlatform>
                <Tasa />
              </LayoutPlatform>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default RouterWrapper;
