import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from '@firebase/storage'
import { getAuth } from "firebase/auth";
import { v4 } from 'uuid'

const firebaseConfig = {
  apiKey: "AIzaSyBtszDx35a2ABWwXCeeo7ViXYSEHxWnBYM",
  authDomain: "lendy-b18c6.firebaseapp.com",
  projectId: "lendy-b18c6",
  storageBucket: "lendy-b18c6.firebasestorage.app",
  messagingSenderId: "504239023765",
  appId: "1:504239023765:web:983e10d5dcf626b8c6dd9c"
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)
export const auth = getAuth(app);
export const storage = getStorage(app)

export async function uploadFile(file, name) {

  // Obtener la extensión del archivo
  const fileExtension = file.name.split('.').pop()

  // Generar un nombre de archivo único con la extensión
  const fileName = `${v4()}.${fileExtension}`

  const storageRef = ref(storage, `lendy/${name}/${fileName}`)
  await uploadBytes(storageRef, file)

  const url = await getDownloadURL(storageRef)
  return url
}