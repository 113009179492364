import React from "react";

function Input({ label, value, type, placeholder, ...rest }) {
  return (
    <div className="mb-3">
      <label
        htmlFor="text"
        className="block mb-2 text-md font-medium text-white "
      >
        {label}
      </label>
      <input
        type={type ? type : "text"}
        id="text"
        className="bg-robin bg-opacity-20 text-white text-sm rounded-lg block w-full p-2.5 focus-0"
        placeholder={placeholder ? placeholder : ""}
        value={value}
        required
        {...rest}
      />
    </div>
  );
}

export default Input;
