import { deleteDoc, doc } from "@firebase/firestore";
import { db } from "../FirebaseConfig.ts";
import { useState } from "react";

const useDeleteDoc = () => {
  const [fetching, setFetching] = useState(false);

  const handleDelete = async (url, id) => {
    setFetching(true);
    try {
      const docRef = doc(db, url, id);
      await deleteDoc(docRef);
    } catch (error) {
      console.error("Error deleting document:", error);
    } finally {
      setFetching(false);
    }
  };

  return { handleDelete, fetching };
};

export default useDeleteDoc;
