"use client";

import React, { useState } from "react";
import Register from "./register";
import { auth } from "../../../FirebaseConfig.ts";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/Loading";
import Login from "./login";

function Container() {
  const [user, loading] = useAuthState(auth);
  const [login, setLogin] = useState(true);

  const navigate = useNavigate();

  if (loading) {
    return <Loading />;
  } else if (user) {
    return navigate("/plataforma");
  }
  return (
    <div>
      {login ? <Login setLogin={setLogin} /> : <Register setLogin={setLogin} />}
    </div>
  );
}

export default Container;
