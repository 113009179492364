import React from "react";
import Banner from "../../components/Banner";
import { useParams } from "react-router-dom";
import { useSnapshotWhere } from "../../hooks/useSnapshotWhere";
import Loading from "../../components/Loading";
import LogoBlack from "../../components/LogoBlack";

function Codeudor() {
  const { id } = useParams();

  const { data: dataCodeudor, isFetching } = useSnapshotWhere("codeudor", {
    filter: {
      firstCondition: "uuid",
      secondCondition: "==",
      thirdCondition: id,
    },
  });

  if (isFetching) return <Loading />;

  if (dataCodeudor.length === 0)
    return (
      <>
        <div className="flex items-center justify-center bg-white py-6">
          <LogoBlack />
        </div>
        <p className="text-xl my-3">No existe la solicitud</p>
      </>
    );

  return (
    <div>
      <Banner codeudor dataCodeudor={dataCodeudor[0]} />
    </div>
  );
}

export default Codeudor;
