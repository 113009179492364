"use client";

import React, { useState } from "react";
import FormStepTwo from "./formStepTwo";

function FormCompany() {
  const [step, setStep] = useState(0);
  const [activeMoney, setActiveMoney] = useState(0);
  const [data, setData] = useState({
    name_company: "",
    email: "",
    name: "",
    lastName: "",
    identity: "",
    expedition: "",
  });

  const [files, setFiles] = useState({
    rut: "",
    fot_cedula: "",
    cam_comercio: "",
  });

  return (
    <div className="bg-richBlack rounded-xl p-3 h-fit">
      <p className="text-white text-center text-3xl font-bold">
        Regístrate como empresa para tus empleadores
      </p>

      <FormStepTwo
        setActiveMoney={setActiveMoney}
        activeMoney={activeMoney}
        setStep={setStep}
        setData={setData}
        data={data}
        files={files}
        setFiles={setFiles}
      />
    </div>
  );
}

export default FormCompany;
