import React from "react";
import Spinner from "../../../components/Spinner";
import useUpdateDoc from "../../../hooks/useUpdateDoc";

function TablePagos({ loading, schedule, payment, data }) {
  const { handleUpdate } = useUpdateDoc();
  const handleUpdatePayment = (id) => {
    const existId = data.payments.find((payment) => payment === id.toString());

    if (existId) {
      //ya existe el pago lo removemos
      const removeData = data.payments.filter((el) => el !== existId);

      handleUpdate("requests", data.id, {
        payments: removeData,
      });
    } else {
      const insertArray = data.payments.push(id.toString());

      const newArray = data.payments;

      handleUpdate("requests", data.id, {
        payments: newArray,
      });
      ///el pago no existe lo insertamos
    }
  };
  return (
    <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50">
        <tr>
          <th className="px-6 py-3">Cuotas</th>
          <th className="px-6 py-3">Fecha de pago</th>
          <th className="px-6 py-3">Valor préstamo</th>
          <th className="px-6 py-3">Cuota a pagar</th>
          <th className="px-6 py-3">Capital</th>
          <th className="px-6 py-3">Interés</th>
          <th className="px-6 py-3">Saldo deuda</th>
          {payment && <th className="px-6 py-3">Pago</th>}
        </tr>
      </thead>
      <tbody className="bg-white">
        {loading ? (
          <tr>
            <td colSpan="100%" className="text-center">
              <Spinner />
            </td>
          </tr>
        ) : (
          schedule.map((row) => (
            <tr
              className="bg-white border-b  dark:border-gray-700"
              key={row.cuota}
            >
              <td className="px-6 py-4">{row.cuota}</td>
              <td className="px-6 py-4">
                {row.fechaPago === "Invalid Date" ? "" : row.fechaPago}
              </td>
              <td className="px-6 py-4">
                ${row.valorPrestamo.toLocaleString()}
              </td>
              <td className="px-6 py-4">${row.cuotaAPagar.toLocaleString()}</td>
              <td className="px-6 py-4">${row.capital.toLocaleString()}</td>
              <td className="px-6 py-4">${row.interes.toLocaleString()}</td>
              <td className="px-6 py-4">${row.saldoDeuda.toLocaleString()}</td>
              {payment && (
                <td className="px-6 py-4">
                  <div
                    onClick={() => handleUpdatePayment(row.cuota)}
                    className={` rounded-full  w-5 h-5 border-gray-700 cursor-pointer ${
                      row.payment ? "bg-blue-700" : "border"
                    } `}
                  />
                </td>
              )}
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
}

export default TablePagos;
